import React from 'react'
import { graphql, Link } from 'gatsby'
import { FaHouseUser, FaLeaf, FaStoreAlt } from 'react-icons/fa'
import { PortableText } from '@portabletext/react'

import Showcase from '../components/Showcase'
import Footer from '../components/Layout/Footer'
// import PageBanner from '../components/PageBanner'

import * as styles from '../styles/pages/faq.module.scss'

export const Head = () => <title>FAQ | GROWoneAFRICA&trade;</title>

const FAQPage = ({ data }) => {
  const faqs = data.allSanityFaq.nodes

  const categories = faqs
    .sort((a, b) => a.order - b.order)
    .reduce((acc, curr) => {
      switch (curr.category) {
        case 'club-member':
          acc.clubMember.push(curr)
          break;
        case 'cultivator':
          acc.cultivator.push(curr)
          break;
        case 'club':
          acc.club.push(curr)
          break;
        default:
          acc.misc.push(curr)
      }

      return acc
    }, { clubMember: [], cultivator: [], club: [], misc: [] })

  const faqElements = (faqsArr) => faqsArr.map(({ _id, category, question, _rawAnswer}) => (
    <details key={_id} className={styles.faq}>
      <summary>{question}</summary>
      <div className={styles.answer}>
        <PortableText
          value={_rawAnswer}
        />
      </div>
    </details>
  ))

  return (
    <>
      <main id={styles.faqPage}>
        {/* <PageBanner
          title="FAQ"
          backgroundImage={data.outdoorGrowAmongstTheMountainsImage}
        /> */}
        <Showcase image={data.showcaseImage} />
        <div id="next" className="page-container">
          <section id={styles.categoryButtonsContainer}>
            <Link to="#club-member" className={styles.categoryButton}>
              <FaHouseUser />
              <span>Club Member Questions</span>
            </Link>
            <Link to="#cultivator" className={styles.categoryButton}>
              <FaLeaf />
              <span>Cultivator/Processing Questions</span>
            </Link>
            <Link to="#club" className={styles.categoryButton}>
              <FaStoreAlt />
              <span>Social Club Questions</span>
            </Link>
          </section>

          <section className={styles.faqsContainer}>
            <h2 id="club-member">Club Member Questions</h2>
            {faqElements(categories.clubMember)}
            <h2 id="cultivator">Cultivating Processing Member (CPM) Questions</h2>
            {faqElements(categories.cultivator)}
            <h2 id="club">Social Club Questions</h2>
            {faqElements(categories.club)}
          </section>
        </div>
      </main>
      <Footer />
    </>
  )
}

export const query = graphql`
  query FAQPageQuery {
    showcaseImage: file(relativePath: {eq: "faq-page/banner.jpg"}) {
      childImageSharp {
        gatsbyImageData
      }
    }
    allSanityFaq {
      nodes {
        _id
        category
        order
        question
        _rawAnswer
      }
    }
  }
`

export default FAQPage